import styles from '@/components/template/footer/FooterAddress/FooterAddress.module.scss'

const FooterAddress = () => {
  return (
    <div className={styles.root}>
      <p className="mb-2">
        <span className={styles.title}>Carreisen Ostschweiz GmbH</span>
        Hauptstrasse 33
        <br />
        CH-8580 Sommeri TG
      </p>
      <p className="mb-4">
        <span className={styles.country}>Tel</span>
        <a href="tel:0041716331026">+41 (0)71 633 10 26</a>
      </p>
      <p className="mb-4">
        <a href="mailto:info@carreisen-ostschweiz.ch">info@carreisen-ostschweiz.ch</a>
      </p>
    </div>
  )
}

export default FooterAddress
