import styles from './FooterMenu.module.scss'
import Link from 'next/link'
import { MenuData, MenuItem } from '@/components/template/footer/types'
import BsAccordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { useState } from 'react'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'

function CustomToggle({ children, eventKey }: any) {
  const [active, setActive] = useState(false)
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setActive(!active)
  })

  return (
    <button type="button" className={`${active && styles.active} ${styles.accordionButton}`} onClick={decoratedOnClick}>
      {children}
    </button>
  )
}

const FooterMenu = (data: MenuData) => {
  return (
    <div className={styles.root}>
      <div className={`${styles.menu} d-md-none mb-0`}>
        <BsAccordion className={styles.accordion} defaultActiveKey="1">
          <Card className={styles.accordionItem}>
            <Card.Header className={styles.accordionHeader}>
              <CustomToggle eventKey="0">{data.title}</CustomToggle>
            </Card.Header>
            <BsAccordion.Collapse eventKey="0">
              <Card.Body className={styles.cardBody}>
                {data.items && data.items.length > 0 && (
                  <ul>
                    {data.items.map((item: MenuItem, index: number) => (
                      <li key={index}>
                        <Link href={item.href}>
                          <a data-tr-click="footer:navigation_link:click">{item.label}</a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </Card.Body>
            </BsAccordion.Collapse>
          </Card>

          {/*<BsAccordion.Item eventKey="0" >*/}
          {/*  <BsAccordion.Header className={styles.accordionButton}>{item.label}</BsAccordion.Header>*/}
          {/*  <BsAccordion.Body></BsAccordion.Body>*/}
          {/*</BsAccordion.Item>*/}
        </BsAccordion>
      </div>
      <div className={`${styles.menu} d-none d-md-block`}>
        {typeof data.title === 'string' ? (
          <div className={styles.title}>{data.title}</div>
        ) : (
          <a href={data.title.href} className={styles.title} data-tr-click="footer:navigation_link:click">
            {data.title.label}
          </a>
        )}
        {data.items && data.items.length > 0 && (
          <ul>
            {data.items.map((item: MenuItem, index: number) => (
              <li key={index}>
                <Link href={item.href}>
                  <a data-tr-click="footer:navigation_link:click">{item.label}</a>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default FooterMenu
